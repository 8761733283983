import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr"
import QRCode from "qrcode.react"

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";

import Api from "../../api"
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import AssetForm from "../../components/AssetForm";

const QrView = ({ asset }) => {
  const QrCode = asset.tag_id ? <QRCode size={300} value={`${asset.tag_id}`} /> : null
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">QR Code</CardTitle>
      </CardHeader>
      <CardBody className='qr-view'>
        {QrCode}
        <h6 className="card-subtitle text-muted">
          Asset Tag ID
      </h6>
        <h2>{asset.tag_id}</h2>
      </CardBody>
    </Card >
  )
};

const AssetNew = ({ history, ...props }) => {

  const [asset, setAsset] = useState({
    id: null,
    tag_id: '',
    location: '',
    points_to: '',
    radio_type: '',
    ip_address: '',
  });

  const handleTypeChange = (radioType) => {
    setAsset({
      ...asset,
      radio_type: radioType.value
    })
  }

  const handleSelectChange = (field, value) => {
    setAsset({
      ...asset,
      [field]: value.value
    })
  }

  const handleChange = event => {
    setAsset({
      ...asset,
      [event.target.name]: event.target.value,
    })
  };

  const handleSubmit = () => {
    Api.post(`/main/assets/`, asset).then((res) => {
      toastr.success(`Created asset tag ${res.data.tag_id}`)
      history.push(`/assets`)
    }).catch((err) => {

      if (err.response.data['tag_id'] === "asset with this tag id already exists.")
        toastr.error("Tag ID already exists")
      else
        toastr.error(`${err}`)
    })
  }


  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Add new tag</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/assets">Assets</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col lg="8">
          <AssetForm
            asset={asset}
            onChange={handleChange}
            onTypeChange={handleTypeChange}
            handleSelectChange={handleSelectChange}
            onSubmit={handleSubmit}
          />
        </Col>
        <Col lg="4" >
          <QrView asset={asset} />
        </Col>
      </Row>
    </Container >
  )
}

export default withRouter(AssetNew)