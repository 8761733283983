import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr"
import Select from "react-select";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import Api from "../api"

const AssetForm = ({ asset, handleSelectChange, ...props }) => {
  const [radioTypes, setRadioTypes] = useState([])
  const [buildings, setBuildings] = useState([])

  useEffect(() => {
    Api.get("/main/radio_types/").then((res) => {
      const types = res.data.map(x => ({ value: x.id, label: x.name }))
      setRadioTypes(types)
    }).catch((err) => {
      toastr.error("Error fetching radio types")
    })
    Api.get("/main/buildings/").then((res) => {
      const types = res.data.map(x => ({ value: x.id, label: x.name }))
      setBuildings([
        { value: '', label: 'unassigned' },
        ...types
      ])
    }).catch((err) => {
      toastr.error("Error fetching buildings")
    })

  }, [asset])

  return (
    <Card>
      <CardBody>
        <Form>
          <FormGroup>
            <Label>Tag ID</Label>
            <Input
              type="text"
              name="tag_id"
              placeholder="Tag ID"
              value={asset.tag_id}
              onChange={props.onChange} />
          </FormGroup>
          <FormGroup>
            <Label>Location</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="location"
              options={buildings}
              defaultValue={{ value: '', label: 'unassigned' }}
              value={buildings.find(x => x.value === asset.location)}
              onChange={(val) => handleSelectChange('location', val)}
              isSearchable
            />
          </FormGroup>
          <FormGroup>
            <Label>Points To</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="points_to"
              defaultValue={{ value: '', label: 'unassigned' }}
              options={buildings}
              value={buildings.find(x => x.value === asset.points_to)}
              onChange={(val) => handleSelectChange('points_to', val)}
              isSearchable
            />
          </FormGroup>
          <FormGroup>
            <Label>Radio Type</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="radio_type"
              options={radioTypes}
              value={radioTypes.find(x => x.value === asset.radio_type)}
              onChange={(val) => handleSelectChange('radio_type', val)}
              isSearchable
            />
          </FormGroup>
          <FormGroup>
            <Label>IP</Label>
            <Input
              type="text"
              name="ip_address"
              placeholder="IP Address"
              value={asset.ip_address}
              onChange={props.onChange} />
          </FormGroup>
          <Button color="primary" onClick={props.onSubmit}>Submit</Button>
        </Form>
      </CardBody>
    </Card>
  )
};

export default AssetForm