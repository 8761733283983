import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr"

import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap";


import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import AssetsTable from "./AssetsTable"
import Api from "../../api"



const Assets = (props) => {
  const [assets, setAssets] = useState([])
  let { query } = useParams()

  useEffect(() => {
    Api.get(`/main/assets/?search=${query}`).then((response) => {
      setAssets(response.data)
    }).catch((err) => {
      toastr.error(`${err}`)
    })
  }, [query]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Results for {query}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>
            <Link to="/assets">Assets</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col lg="12">
          <AssetsTable assets={assets} />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Assets)
