import React, { useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom";

import { toggleSidebar } from "../redux/actions/sidebarActions";
import { authLogout } from "../redux/actions/authActions";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";



const NavbarToggle = connect(store => ({
  app: store.app
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarSearch = connect(store => ({
  sidebar: store.sidebar
}))(({ sidebar, ...props }) => {
  const [searchQuery, setSearchQuery] = useState("")

  const handleChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleSearch = (event) => {
    event.preventDefault()
    // tell the parent component to update the browser url
    props.onSearch(searchQuery)
    // empty the search box after hitting enter
    setSearchQuery("")
  }
  return (
    <Form inline onSubmit={handleSearch}>
      <Input
        type="text"
        placeholder="Search projects..."
        aria-label="Search"
        onChange={handleChange}
        value={searchQuery}
        className={classNames(
          "form-control-lite",
          !sidebar.isOnRight || "text-right"
        )}
      />
    </Form>
  );
});

const NavbarDropdowns = connect(store => ({
  sidebar: store.sidebar
}))(({ sidebar, ...props }) => {
  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                fixedWidth
                className="mr-2 align-middle"
              />
              <a onClick={props.handleLogout}>
                Sign out
              </a>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
});

const NavbarComponent = ({ sidebar, ...props }) => {
  const handleSearch = (query) => {
    console.log(query)
    if (query === "")
      props.history.push(`/assets/`)
    else
      props.history.push(`/assets/search/${query}`)

  }

  return (
    <Navbar expand className="navbar-theme">
      {!sidebar.isOnRight ? (
        <React.Fragment>
          <NavbarToggle />

          <NavbarDropdowns {...props} />
        </React.Fragment>
      ) : (
          <React.Fragment>
            <NavbarDropdowns {...props} />

            <NavbarToggle />
          </React.Fragment>
        )}
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      handleLogout: authLogout
    },
    dispatch
  );
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarComponent));
