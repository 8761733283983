import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr'

import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap";


import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import BuildingsTable from "./BuildingsTable"
import Api from "../../api"


const Assets = (props) => {
  const [buildings, setBuildings] = useState([])

  const loadBuildings = () => {
    Api.get("/main/buildings/").then((response) => {
      setBuildings(response.data)
    }).catch((err) => {
      toastr.error(`${err}`)
    })
  }

  useEffect(() => {
    loadBuildings() // init load buildings 
  }, []);

  const handleDelete = (id) => {
    let conf = window.confirm("Are you sure?")
    if (conf) {
      Api.delete(`/main/buildings/${id}/`).then(res => {
        toastr.success("Building deleted")
        loadBuildings()
      }).catch(err => {
        toastr.error("Error deleting building")
      })
    }
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Buildings</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>
            <Link to="/buildings">Buildings</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col lg="12">
          <BuildingsTable buildings={buildings} onDelete={handleDelete} />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Assets)
