import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr'

import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap";


import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import AssetsTable from "./AssetsTable"
import Api from "../../api"


const Assets = (props) => {
  const [assets, setAssets] = useState([])

  const loadAssets = () => {
    Api.get("/main/assets/").then((response) => {
      setAssets(response.data)
    }).catch((err) => {
      toastr.error(`${err}`)
    })
  }

  useEffect(() => {
    loadAssets()
  }, []);

  const handleUnassign = (id) => {
    let conf = window.confirm("Are you sure?")

    if (conf) {
      console.log("Unassign asset with id", id)
      Api.put(`/main/assets/unassign/${id}/`).then((response) => {
        loadAssets()
        toastr.success("Successfully unassigned")
      }).catch((err) => {
        toastr.error(`${err}`)
      })
    }
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Tracked Assets</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>
            <Link to="/assets">Assets</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col lg="12">
          <AssetsTable assets={assets} onUnassign={handleUnassign} />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Assets)
