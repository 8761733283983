import React from "react";
import { connect } from 'react-redux';

import Main from "../components/Main";
import Wrapper from "../components/Wrapper";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar"

const Mobile = ({ sidebar, children }) => (
    <React.Fragment>
        <Wrapper>
            {!sidebar.isOnRight && <Sidebar />}
            <Main>
                <Navbar />
                <Content>
                    {children}
                </Content>

            </Main>
            {sidebar.isOnRight && <Sidebar />}
        </Wrapper>
    </React.Fragment>
)



const mapStateToProps = state => {
    return {
        sidebar: state.sidebar
    };
};


export default connect(mapStateToProps)(Mobile);
