import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom"

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


const { SearchBar } = Search;



const BuildingsTable = ({ buildings, onDelete: handleDelete }) => {

    const tableColumns = [
        {
            dataField: "id",
            text: "Id",
            sort: true,
            headerStyle: { width: '10%' }
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            headerStyle: { width: '70%' }
        },
        {
            dataField: "actions",
            formatter: (cell, row) => (
                <React.Fragment>
                    <Link to={`/building/${row.id}`}>
                        <Button id={row.id}>Edit</Button>
                    </Link>
                    <Button
                        style={{ marginLeft: '15px' }}
                        color={"danger"}
                        id={row.id}
                        onClick={() => handleDelete(row.id)}>
                        Delete
                    </Button>

                </React.Fragment>

            ),
            text: "Actions",
            sort: false,
        },
    ];

    return (
        <Card>
            <CardHeader>
                <div style={{ float: 'left' }}>
                    <CardTitle tag="h5">Buildings</CardTitle>
                    <h6 className="card-subtitle text-muted">
                        Listing of Buildings
                    </h6>
                </div>

                <div style={{ float: 'right' }}>
                    <Link to="/buildings/new">
                        <Button>
                            New
                            </Button>
                    </Link>

                </div>
            </CardHeader>
            <CardBody>
                <ToolkitProvider
                    keyField="id"
                    data={buildings}
                    columns={tableColumns}
                    search
                >
                    {props => (
                        <React.Fragment>
                            <div style={{ float: 'right' }}>
                                <SearchBar {...props.searchProps} />
                            </div>

                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                bordered={false}
                                pagination={paginationFactory({
                                    sizePerPage: 25,
                                    sizePerPageList: [5, 10, 25, 50]
                                })}
                            />
                        </React.Fragment>
                    )}

                </ToolkitProvider>
            </CardBody>
        </Card>
    );
};

export default BuildingsTable