import React, { useEffect, useState } from "react";

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

const AssetLogTable = ({ logs }) => {

    const defaultSorted = [{
        dataField: 'datetime',
        order: 'desc'
    }];

    const tableColumns = [
        {
            dataField: "datetime",
            text: "datetime",
            sort: true,
            headerStyle: { width: '30%' }
        },
        {
            dataField: "change",
            text: "Change",
            sort: true,
        },
    ];


    return (

        <ToolkitProvider
            keyField="id"
            data={logs}
            columns={tableColumns}
            search
        >
            {props => (
                <React.Fragment>
                    <div style={{ float: 'right' }}>
                        <SearchBar {...props.searchProps} />
                    </div>

                    <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        defaultSorted={defaultSorted}
                        pagination={paginationFactory({
                            sizePerPage: 25,
                            sizePerPageList: [5, 10, 25, 50]
                        })}
                    />
                </React.Fragment>
            )}

        </ToolkitProvider>

    );
};

export default AssetLogTable