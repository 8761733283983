import React from "react"

import {BrowserView, MobileView} from "react-device-detect"
import { Redirect } from "react-router-dom"

const Landing = ({...props}) => {

    return (
        <React.Fragment>
            <BrowserView>
                <Redirect to={'/assets'} />
            </BrowserView>

            <MobileView>
                <Redirect to={'/scan'} />
            </MobileView>
        </React.Fragment>
    )
}

export default Landing