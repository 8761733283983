import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom"
import { toastr } from "react-redux-toastr"

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Api from "../../api"

const { SearchBar } = Search;


const AssetsTable = ({ assets, onUnassign: handleUnassign }) => {
    const [buildings, setBuildings] = useState([])
    const [radioTypes, setRadioTypes] = useState([])
    const [normalizedAssets, setNormalizedAssets] = useState([])

    const tableColumns = [
        {
            dataField: "tag_id",
            text: "Tag ID",
            sort: true,
            headerStyle: { width: '10%' }
        },
        {
            dataField: "location",
            text: "Location",
            sort: true,
            formatter: (cell, row) => (
                cell ? cell : "unassigned"
            ),
        },
        {
            dataField: "points_to",
            text: "Points to",
            sort: true,
            formatter: (cell, row) => (
                cell ? cell : "unassigned"
            ),
        },
        {
            dataField: "radio_type",
            text: "Hardware",
            sort: true
        },
        {
            dataField: "ip_address",
            text: "IP Address",
            sort: true,
        },
        {
            dataField: "actions",
            formatter: (cell, row) => (
                <React.Fragment>
                    <Link to={`/asset/${row.id}`}>
                        <Button id={row.id}>Edit</Button>
                    </Link>
                    {
                        (row.location || row.points_to) &&
                        <Button
                            style={{ marginLeft: '10px' }}
                            color={"outline-danger"}
                            onClick={() => handleUnassign(row.id)}>Unassign</Button>
                    }

                </React.Fragment>
            ),
            text: "Actions",
            sort: false,
        },
    ];

    useEffect(() => {
        Api.get("/main/buildings/").then((res) => {
            setBuildings(res.data)
        }).catch((err) => {
            toastr.error("Error fetching buildings")
        })
        Api.get("/main/radio_types/").then((res) => {
            setRadioTypes(res.data)
        }).catch((err) => {
            toastr.error("Error fetching radio types")
        })
    }, [])

    useEffect(() => {
        setNormalizedAssets(assets.map(a => {
            let loc_bldg = buildings.find(b => b.id === a.location)
            let points_to_bldg = buildings.find(b => b.id === a.points_to)
            let radio_type = radioTypes.find(b => b.id === a.radio_type)
            return ({
                ...a,
                location: loc_bldg ? loc_bldg.name : a.location,
                points_to: points_to_bldg ? points_to_bldg.name : a.points_to,
                radio_type: radio_type ? radio_type.name : a.radio_type,
            })
        }))
    }, [assets, buildings, radioTypes])

    return (
        <Card>
            <CardHeader>
                <div style={{ float: 'left' }}>
                    <CardTitle tag="h5">Radios</CardTitle>
                    <h6 className="card-subtitle text-muted">
                        Listing of assets
                    </h6>
                </div>

                <div style={{ float: 'right' }}>
                    <Link to="/assets/new">
                        <Button>
                            New
                            </Button>
                    </Link>

                </div>
            </CardHeader>
            <CardBody>
                <ToolkitProvider
                    keyField="id"
                    data={normalizedAssets}
                    columns={tableColumns}
                    search
                >
                    {props => (
                        <React.Fragment>
                            <div style={{ float: 'right' }}>
                                <SearchBar {...props.searchProps} />
                            </div>

                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                bordered={false}
                                pagination={paginationFactory({
                                    sizePerPage: 25,
                                    sizePerPageList: [5, 10, 25, 50]
                                })}
                            />
                        </React.Fragment>
                    )}

                </ToolkitProvider>
            </CardBody>
        </Card>
    );
};

export default AssetsTable