import React, { useState, useEffect } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr"

import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap";

import Api from "../../api"
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BuildingForm from "../../components/BuildingForm";


const BuildingEdit = ({ history }) => {
  let { id } = useParams();

  const [building, setBuilding] = useState({
    id: null,
    name: '',
  });

  const handleChange = event => {
    setBuilding({
      ...building,
      [event.target.name]: event.target.value,
    })
  };

  const handleSubmit = () => {
    Api.put(`/main/buildings/${building.id}/`, building).then((res) => {
      toastr.success(`Updated building ${res.data.name}`)
      history.push('/buildings')
    }).catch((err) => {
      toastr.error(`${err}`)
    })

  }


  useEffect(() => {
    Api.get(`/main/buildings/${id}/`).then((res) => {
      setBuilding(res.data)
    }).catch((err) => {
      toastr.error(`${err}`)
    })
  }, [id]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Editing Building {building.name}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/buildings">Buildings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col lg="12">
          <BuildingForm
            building={building}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        </Col>
      </Row>
    </Container >
  )
}

export default withRouter(BuildingEdit)