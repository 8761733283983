import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const BuildingForm = ({ building, ...props }) => {

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Edit form</CardTitle>
      </CardHeader>
      <CardBody>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={building.name}
              onChange={props.onChange} />
          </FormGroup>

          <Button color="primary" onClick={props.onSubmit}>Submit</Button>
        </Form>
      </CardBody>
    </Card>
  )
};

export default BuildingForm