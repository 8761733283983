import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr"

import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap";

import Api from "../../api"
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import BuildingForm from "../../components/BuildingForm";


const BuildingNew = ({ history }) => {

  const [building, setBuilding] = useState({
    id: null,
    name: '',
  });

  const handleChange = event => {
    setBuilding({
      ...building,
      [event.target.name]: event.target.value,
    })
  };

  const handleSubmit = () => {
    Api.post(`/main/buildings/`, building).then((res) => {
      toastr.success(`Created building ${res.data.name}`)
      history.push(`/buildings`)
    }).catch((err) => {
      toastr.error(`${err}`)
    })

  }


  return (
    <Container fluid>
      <Header>
        <HeaderTitle>New Building</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/buildings">Buildings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col lg="12">
          <BuildingForm
            building={building}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        </Col>
      </Row>
    </Container >
  )
}

export default withRouter(BuildingNew)