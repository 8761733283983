import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux"
import { assetRoutes, scanRoute } from "./index"


import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import MobileLayout from "../layouts/Mobile"

import ScrollToTop from "../components/ScrollToTop";

import Login from "../pages/Login"

const childRoutes = (Layout, routes, isAuthenticated) =>
  routes.map(({ children, secure, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, secure, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => {
            return (
              (secure && !isAuthenticated) ?
                <Redirect to={"/login"} /> :
                (<Layout>
                  <Component {...props} />
                </Layout>)
            )
          }}
        />
      ))
    ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            (secure && !isAuthenticated) ?
              <Redirect to={"/login"} /> :

              <Layout>
                <Component {...props} />
              </Layout>
          )}
        />
      )
  );

const Routes = ({ isAuthenticated }) => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route path={"/login"} exact render={() => (
          <AuthLayout>
            <Login />
          </AuthLayout>
        )} />
        {childRoutes(MobileLayout, [scanRoute], isAuthenticated)}
        {childRoutes(DashboardLayout, [assetRoutes], isAuthenticated)}
      </Switch>
    </ScrollToTop>
  </Router>
);

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Routes);
