import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

import { authLogin } from "../../redux/actions/authActions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleLogin = event => {
    event.preventDefault()
    this.props.performLogin(this.state.username, this.state.password);
  };

  render() {
    return (
      (this.props.isAuthenticated) ? <Redirect to={"/"} /> :
        <React.Fragment>
          <div className="text-center mt-4">
            <h2>Asset Tracking</h2>
            <p className="lead">Please sign in to continue</p>
          </div>

          <Card>
            <CardBody>
              <div className="m-sm-4">
                <div className="text-center"></div>
                <Form>
                  <FormGroup>
                    <Label>Username</Label>
                    <Input
                      bsSize="lg"
                      type="text"
                      name="username"
                      placeholder="Enter your username"
                      onChange={this.handleChange}
                      value={this.state.username}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      onChange={this.handleChange}
                      value={this.state.password}
                    />
                    <small>
                      <Link to="/auth/reset-password">Forgot password?</Link>
                    </small>
                  </FormGroup>
                  <div>
                    <CustomInput
                      type="checkbox"
                      id="rememberMe"
                      label="Remember me next time"
                      defaultChecked
                    />
                  </div>
                  <div className="text-center mt-3">
                    <Button type="submit" onClick={this.handleLogin} color="primary" size="lg">
                      Sign in
                  </Button>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      performLogin: authLogin
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
