import {
    faHome,
} from "@fortawesome/free-solid-svg-icons";

// pages 
import Assets from "../pages/Assets"
import AssetEdit from "../pages/Assets/AssetEdit"
import AssetNew from "../pages/Assets/AssetNew"
import AssetSearch from "../pages/Assets/AssetSearch"
import Scan from "../pages/Assets/Scan";

import Buildings from "../pages/Buildings"
import BuildingEdit from "../pages/Buildings/BuildingEdit"
import BuildingNew from "../pages/Buildings/BuildingNew"

import Landing from "../pages/Landing"

export const assetRoutes =
{
    name: "Assets",
    icon: faHome,
    path: "/",
    header: "Main",
    children: [
        {
            name: "Landing",
            path: "/",
            component: Landing,
            secure: true,
            showInSidebar: false
        },
        {
            name: "Radios",
            path: "/assets",
            component: Assets,
            secure: true,
            showInSidebar: false
        },
        {
            name: "Radios",
            path: "/assets",
            component: Assets,
            secure: true,
            showInSidebar: true
        },
        {
            name: "Buildings",
            path: "/buildings",
            component: Buildings,
            secure: true,
            showInSidebar: true
        },
        {
            name: "Building New",
            path: "/buildings/new",
            component: BuildingNew,
            secure: true,
            showInSidebar: false
        },
        {
            name: "Building Edit",
            path: "/building/:id",
            component: BuildingEdit,
            secure: true,
            showInSidebar: false
        },
        {
            name: "Search",
            path: "/assets/search/:query",
            component: AssetSearch,
            secure: true,
            showInSidebar: false
        },
        {
            name: "New",
            path: "/assets/new",
            component: AssetNew,
            secure: true,
            showInSidebar: false
        },
        {
            name: "Asset Edit",
            path: "/asset/:id",
            component: AssetEdit,
            secure: true,
            showInSidebar: false
        },

    ]
}

export const scanRoute = {
    name: "Scan",
    icon: faHome,
    path: "/scan",
    header: "Scan",
    secure: true,
    component: Scan
}

export default [
    assetRoutes,
]