import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReduxToastr from "react-redux-toastr";
import "./assets/scss/_app.scss";


import Routes from "./routes/Routes";
import { authSetToken } from './redux/actions/authActions'

class App extends React.Component {
  constructor(props) {
    super(props)
    if (localStorage.getItem('auth_token')) {
      this.props.authSetToken(localStorage.getItem('auth_token'))
    }
  }

  render() {
    return (
      <React.Fragment>
        <Routes />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    authToken: state.auth.authToken,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      authSetToken
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
