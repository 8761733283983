import React, { useState, useEffect } from "react"
import QrReader from "react-qr-reader";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr"

import {
    Col,
    Row,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from "reactstrap";

import Api from "../../api"


const Scan = ({ history }) => {
    const [qrCode, setQrCode] = useState("")
    const [searchText, setSearchText] = useState("")

    const handleQrFound = qr => {
        if (qr)
            setQrCode(qr)
    }

    useEffect(() => {
        if (qrCode !== "") {
            // find asset by tag id
            Api.get(`/main/assets/tag_id/${qrCode}/`).then((res) => {
                history.push(`/asset/${res.data['id']}`)
            }).catch((err) => {
                toastr.error("Asset not found")
                setQrCode("")
            })
        }
    }, [history, qrCode])

    const performSearch = (e) => {
        if (searchText !== "") {
            // find asset by tag id
            Api.get(`/main/assets/tag_id/${searchText}/`).then((res) => {
                history.push(`/asset/${res.data['id']}`)
            }).catch((err) => {
                toastr.error("Asset not found")
                setQrCode("")
            })
        }
    }

    const handleQrError = err => {
        console.log("QR Scan error", err)
    }

    return (
        <Row>
            <Col md={12}>
                <QrReader
                    delay={200}
                    onError={handleQrError}
                    onScan={handleQrFound}
                />
            </Col>
            <Col md={12} style={{ padding: '20px' }} >
                <Form>
                    <FormGroup style={{ fontSize: '1.2em' }}>
                        <Label>Search Tag ID</Label>
                        <Row>
                            <Col xs={9}>
                                <Input
                                    type="tel"
                                    name="tag_id"
                                    placeholder="Tag ID"
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }} />
                            </Col>
                            <Col>
                                <Button color="primary" onClick={performSearch}>Search</Button>
                            </Col>
                        </Row>

                    </FormGroup>
                </Form>
            </Col>
        </Row>
    )
}

export default withRouter(Scan)