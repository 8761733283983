import * as types from "../constants";
import { toastr } from 'react-redux-toastr'
import Api from "../../api"


export const authLogin = (username, password) => dispatch => {
  Api.post("/auth/", {
    username,
    password
  })
    .then((response) => {
      const token = response.data['token']
      toastr.success("Successfully logged in")
      console.log("Setting auth token")

      authSetToken(token)(dispatch)
    })
    .catch((err) => {
      toastr.error("Login failed")
      authLogout()
    });
};

export const authLogout = () => dispatch => {
  localStorage.removeItem("auth_token")
  delete Api.defaults.headers.common.Authorization;
  dispatch({
    type: types.AUTH_DEL_TOKEN
  });
};

export const authSetToken = (authToken) => dispatch => {
  localStorage.setItem("auth_token", authToken);
  Api.defaults.headers.common.Authorization = `Token ${authToken}`;
  return dispatch({
    type: types.AUTH_SET_TOKEN,
    authToken: authToken,
  });
};
